import React, { useState } from 'react';
import { projectsData, projectsNav } from './Data';
import WorkItems from './WorkItems';

const Works = () => {
    const [projects, setProjects] = useState(projectsData); // Start with all projects
    const [active, setActive] = useState(0);

    const handleClick = (e, index) => {
        const selectedName = e.target.textContent.toLowerCase(); // Convert to lowercase
        setActive(index);

        // Filter projects based on the selected category
        if (selectedName === 'all') {
            setProjects(projectsData); // Show all projects when "All" is selected
        } else {
            const filteredProjects = projectsData.filter(
                (project) => project.category === selectedName
            );
            setProjects(filteredProjects);
        }
    };

    return (
        <>
            <div className="work__filters">
                {projectsNav.map((item, index) => (
                    <span
                        onClick={(e) => handleClick(e, index)}
                        className={`${active === index ? 'active-work' : 'work__item'}`}
                        key={index}
                    >
                        {item.name}
                    </span>
                ))}
            </div>

            <div className="work__container container grid">
                {projects.length > 0 ? (
                    projects.map((item) => (
                        <WorkItems item={item} key={item.id} />
                    ))
                ) : (
                    <p>No projects found.</p>
                )}
            </div>
        </>
    );
};

export default Works;

import Image1 from '../../assets/4.jpg';
import Image2 from '../../assets/1.jpg';
import Image3 from '../../assets/2.jpg';
import Image4 from '../../assets/3.jpg';


export const Data = [
        {
          id: 1,
          image: Image1,
          title: "John",
          description:
            "Working with Seth was a game-changer for our company. He built a seamless, high-performance web and mobile application that transformed how we engage with our customers. His deep technical expertise, especially in React, Next.js, and Node.js, ensured we had a scalable and user-friendly platform. Beyond his skills, his professionalism and commitment to delivering quality work were truly commendable.",
        },
        {
          id: 2,
          image: Image2,
          title: "Joyce",
          description:
            "Seth is one of the most talented and resourceful engineers I’ve had the pleasure of working with. His ability to handle both frontend and backend challenges with ease makes him an invaluable asset to any development team. Whether it’s optimizing databases, implementing AI-driven features, or refining cloud infrastructure, he consistently delivers outstanding solutions. His problem-solving mindset and willingness to mentor others make him a great team player.",
        },
        {
          id: 3,
          image: Image3,
          title: "Sara",
          description:
            "Seth is a top-tier Full Stack Engineer with an exceptional ability to build and optimize scalable applications. His expertise in JavaScript, Python, and Go, combined with his deep understanding of SQL and NoSQL databases, has greatly contributed to our company's success. His innovative approach and ability to integrate AI solutions into our projects have given us a competitive edge. He is proactive, reliable, and always eager to learn and adapt to new technologies.",
        },
        {
          id: 4,
          image: Image4,
          title: "Mark",
          description:
            "Seth played a crucial role in the development of our platform, from architecting the backend to crafting an intuitive and responsive frontend. His problem-solving skills and ability to work under tight deadlines were remarkable. Thanks to his contributions, we were able to launch on time with a robust and scalable solution. I highly recommend Seth to anyone looking for a dedicated and highly skilled engineer.",
        },
]
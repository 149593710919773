import React from 'react';

const Scoial = () => {
  return (
    <div className="home__social">
        <a href="https://x.com/skyino7" target="_blank" className="home__social-icon">
            <i class="uil uil-twitter"></i>
        </a>
        <a href="https://www.linkedin.com/in/skyino7/" target="_blank" className="home__social-icon">
            <i class="uil uil-linkedin"></i>
        </a>
        <a href="https://github.com/skyion7" target="_blank" className="home__social-icon">
            <i class="uil uil-github"></i>
        </a>
        <a href="https://instagram.com/skyino7" target="_blank" className="home__social-icon">
            <i class="uil uil-instagram"></i>
        </a>
        <a href="https://facebook.com/skyino" target="_blank" className="home__social-icon">
            <i class="uil uil-facebook"></i>
        </a>
    </div>
  )
}

export default Scoial
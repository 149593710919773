import Work1 from "../../assets/bookdonation.jpg";
import Work2 from "../../assets/savings.jpg";
import Work3 from "../../assets/church.jpg";
import Work4 from "../../assets/radio.jpg";
import Work5 from "../../assets/school.jpg";

export const projectsData = [
    {
        id: 1,
        image: Work1,
        title: "Book Campaign Platform",
        description: "A web platform that allows users to create and donate to book campaigns, supporting education initiatives.",
        category: "web",
        link: "https://www.helpanother.one/",
    },
    {
        id: 2,
        image: Work2,
        title: "Savings App - Coming Soon",
        description: "A application that enables users to save and withdraw money seamlessly through USSD.",
        category: "app",
        link: "#",
    },
    {
        id: 3,
        image: Work3,
        title: "Church Management System - Coming Soon",
        description: "A digital platform for managing church members, attendance, and donations efficiently.",
        category: "web",
        link: "#"
    },
    {
        id: 4,
        image: Work4,
        title: "Live Radio Streaming",
        description: "An online radio broadcasting feature that allows multiple stations to stream 24/7.",
        category: "digital",
        link: "https://livebeyondthewallradio.online/",
    },
    {
        id: 5,
        image: Work5,
        title: "School Building Fundraising Platform",
        description: "A web-based crowdfunding platform for raising funds to support school infrastructure projects.",
        category: "web",
        link: "https://www.helpanother.one/",
    },
];


export const projectsNav = [
    {
        name: "all",
    },
    {
        name: "web",
    },
    {
        name: "app",
    },
    {
        name: "digital",
    },
];

import React, { useState } from 'react';
import './services.css'

const Services = () => {
    const [toggleState, setToggleState] = useState(0)

    const toggleTab = (index) => {
        setToggleState(index);
    }

  return (
    <section className="services section" id="services">
         <h2 className="section__title">Services</h2>
         <span className="section__subtitle">What i offer</span>

         <div className="services__container container grid">
            <div className="services__content">
                <div>
                    <i className="uil uil-web-grid services__icon"></i>
                    <h3 className="services__title">Website Design <br /> and Development</h3>
                </div>

                <span className="services__button" onClick={() => toggleTab(1)}>
                    View More
                    <i className="uil uil-arrow-right services__button-icon"></i>
                </span>

                <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                        <h3 className="services__modal-title">Website Design and Development</h3>
                        <p className="services__modal-description">
                        I specialize in crafting websites that are not only visually appealing but also functional, user-friendly, and tailored to meet your specific needs.
                        </p>
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Creating visually appealing and fully functional websites tailored to your brand identity.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Ensuring your website looks great and works seamlessly on all devices, including desktops, tablets, and smartphones.
                                </p>
                            </li>


                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Building online stores with secure payment integrations, product catalogs, and user-friendly interfaces.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Developing websites with CMS platforms like WordPress for easy content updates and management.
                                </p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div className="services__content">
                <div>
                    <i className="uil uil-arrow services__icon"></i>
                    <h3 className="services__title">Digital Marketing Specialist</h3>
                </div>

                <span className="services__button" onClick={() => toggleTab(2)}>
                    View More
                    <i className="uil uil-arrow-right services__button-icon"></i>
                </span>

                <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                        <h3 className="services__modal-title">Digital Marketing Specialist</h3>
                        <p className="services__modal-description">
                            With over 8 years of experience, I help businesses grow by implementing effective digital marketing strategies.
                        </p>
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                <b>Brand Positioning & Awareness</b> – Strengthen your company’s presence and stand out in the market.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                <b>Social Media Marketing</b> – Engage your audience and drive conversions through targeted campaigns.
                                </p>
                            </li>


                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                <b>SEO & Content Marketing</b> – Improve search rankings and attract organic traffic.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                <b>Paid Advertising (PPC)</b> – Maximize ROI with data-driven ad campaigns.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                <b>Email & Performance Marketing</b> – Boost customer retention and optimize marketing efforts.
                                </p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div className="services__content">
                <div>
                    <i className="uil uil-edit services__icon"></i>
                    <h3 className="services__title">Consultation <br/> and Training</h3>
                </div>

                <span className="services__button" onClick={() => toggleTab(3)}>
                    View More
                    <i className="uil uil-arrow-right services__button-icon"></i>
                </span>

                <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                        <h3 className="services__modal-title">Consultation <br/>and Training</h3>
                        <p className="services__modal-description">
                        I offer comprehensive consultation and training services designed to empower clients with the knowledge and tools needed to achieve their goals.
                        </p>
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                I tailor my services to meet the specific needs and skill levels of each client.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Practical, project-based learning to ensure clients can apply what they’ve learned effectively.
                                </p>
                            </li>


                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Offering post-training assistance and resources to reinforce learning and address challenges.
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info">
                                Drawing on years of experience to provide reliable advice and best practices.
                                </p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
         </div>
    </section>
  )
}

export default Services
import React from 'react';
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">Seth</h1>
            <ul className="footer__list">
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>

                <li>
                    <a href="#services" className="footer__link">Services</a>
                </li>

                <li>
                    <a href="#portfolio" className="footer__link">Projects</a>
                </li>
            </ul>

            <div className="footer__social">
                <a href="https://x.com/skyino7" target="_blank" className="home__social-icon">
                    <i class="bx bxl-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/in/skyino7/" target="_blank" className="home__social-icon">
                    <i class="bx bxl-linkedin"></i>
                </a>
                <a href="https://github.com/skyion7" target="_blank" className="home__social-icon">
                    <i class="bx bxl-github"></i>
                </a>
                <a href="https://instagram.com/skyino7" target="_blank" className="home__social-icon">
                    <i class="bx bxl-instagram"></i>
                </a>
                <a href="https://facebook.com/skyino" target="_blank" className="home__social-icon">
                    <i class="bx bxl-facebook"></i>
                </a>
            </div>

            <span className="footer__copy">
                &#169; {new Date().getFullYear()} Seth. All right reserved
            </span>
        </div>
    </footer>
  )
}

export default Footer
import React, { useState } from 'react';
import "./qualification.css";

const Qualification = () => {

    const [toggleState, setToggleState] = useState(1)

    const toggleTab = (index) => {
        setToggleState(index);
    }

  return (
    <section className="qualification section">
         <h2 className="section__title">Qualification</h2>
         <span className="section__subtitle">My personel journey</span>

         <div className="qualification__container container">
            <div className="qualification__tabs">

                <div className={toggleState === 1 ? "qualification__button qualification__active  button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(1)}>
                    <i className="uil uil-graduation-cap qualification__active qualification__icon"></i> Education
                </div>

                <div className={toggleState === 2 ? "qualification__button qualification__active  button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(2)}>
                    <i className="uil uil-briefcase-alt qualification__icon"></i> Experience
                </div>

            </div>

            <div className="qualification__sections">
                <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>

                    <div className="qualification__data">

                        <div>
                            <h3 className="qualification__title">AI</h3>
                            <span className="qualification__subtitle">ALX AICE - Cohort 8</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2024 - 2024
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                    </div>

                    <div className="qualification__data">

                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Software Engineering</h3>
                            <span className="qualification__subtitle">ALX SWE - Cohort 16</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2023 - 2024
                            </div>
                        </div>

                    </div>

                    <div className="qualification__data">

                        <div>
                            <h3 className="qualification__title">Web Design</h3>
                            <span className="qualification__subtitle">FreeCodeCamp</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2024 - Present
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                    </div>

                    <div className="qualification__data">

                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Software Engineering</h3>
                            <span className="qualification__subtitle">IPMC</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2013 - 2014
                            </div>
                        </div>

                    </div>

                </div>

                <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>

                    <div className="qualification__data">

                        <div>
                            <h3 className="qualification__title">Freelance</h3>
                            <span className="qualification__subtitle">Developer</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2014 - Present
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                    </div>

                    <div className="qualification__data">

                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Software Engineering</h3>
                            <span className="qualification__subtitle">Techport Solutions</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2022 - 2023
                            </div>
                        </div>

                    </div>

                    <div className="qualification__data">

                        <div>
                            <h3 className="qualification__title">Web Design</h3>
                            <span className="qualification__subtitle">West Africa Civil Society Institute</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2022 - 2023
                            </div>
                        </div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                    </div>

                    <div className="qualification__data">

                        <div></div>

                        <div>
                            <span className="qualification__rounder"></span>
                            <span className="qualification__line"></span>
                        </div>

                        <div>
                            <h3 className="qualification__title">Media Personnel</h3>
                            <span className="qualification__subtitle">SOLCI</span>
                            <div className="qualification__calendar">
                                <i className="uil uil-calendar-alt"></i> 2016 - Present
                            </div>
                        </div>

                    </div>

                </div>

            </div>
         </div>
    </section>
  )
}

export default Qualification